// hooks
import useAuth from '../hooks/useAuth';
//
import createAvatar from '../utils/createAvatar';
import { MAvatar } from './@material-extend';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={
        user.photoURL &&
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAACCCAMAAADovAORAAAAMFBMVEXR1dr////N0tf8/PzR09ni5Oby8/T5+fru7/HZ3ODm6OvW2d3e4OPO0Nb19vfW19x2vUkaAAADaUlEQVR4nO2cC3KDMAxEjS0+BgP3v20haT5NAraEYZUOrwfojkZIa1mOMScnJycn/wKa/r4AImOt7bwvQ+iMRcuJMoZmcMUVNwS0nBXI+LYq/jJ0WiPch8oVb9Reo14a2w9aLxnh9X10NrxmwVM+9Gh1r1C5KHai1Ka3WVNb1G0bFGVwv672msGNlhJh27jamVaFXgppaie9CkoEjQsF7JNe/DeXkrh3Ajq+5Blqi7oDy7Wc4OLTt0vP3BmHVWtW29kHSqhaWzPl1tDiS0y1hYPKTW4RdzxQLbMuzJTA2mAHtlykc+i4XxpWrudV3ZkGKDd8lVzLLwxFBVMbOaKpk2sSzxGn3KPkIivDKXdH0g/BD5BdTSIXp1bS1ZDHCYHFgZ7d+QYSOe3l+13oUbhbHkIvycUdfuzSfH9Vb4WqZB1f7EwJ0iuoujOgviZxuzOgbPg2ucJkQMkVfmooT0aCOlbgPFkvk4syOYIJ2Qxq4G9lpQHmz0XfGqwJG8N2OBPABRJBNgw4tcZ+k4GcIGZxGAL2HrtnjRqQmXCFcYONs7oPiHMYhibuBVZrwy8IcM4UKnae0uUip483KH3WoGEjkpJLWd2hl0Vmki/XGrTSC8mdWMcyJI2JwdVQF0zq3psbVQR3JqVVwJeynoh3Yg019wZFnYOWxP0lMvZ36H23F2Jy8V7sD7FLFWVyY63ty+RqcDdPxFyvLrnRWS/ybvUDsT6sqUuYeBsGTsY+EZOL3Sx9I3oC0tSEKb4YS4oMWYJD1+QfEwy6qtIQ97u1njacdPrRE960ozt4tHvDpt6oqNBry9SxiMM/ASPWuL/Bvs227LXC1sD6BVHJv1irW4je6Z8KdvRmXHl8hK0J/JW3e4TDwY/1ey+5X30w+OMc2qbIPkX4mIzoVx6Ic6hCf4Dgbntkbwx72x7rZRsiS1R7CramkdWuZfZ7TM5vYWnsI9iW/FXoNFz2mQkRf1WXQZ23DNsu7xf2Ts6MsGGvPHjgcu060Lh3aK80We5grc/XF9bJ8Zs0dEAi3HCbZyeS94kb2PiKmPfzCxnYNmUfj8uEX7aYCO5WWwZqecM4OHGvbBhGHFXCnhFfcEpfGmxE/Psje7qaZWphcAUP7rMgywbhavl2ZJPgHpMLUzaIkrcHqZXtakjfyGRgcW79AwAPJ5ZvAxqWAAAAAElFTkSuQmCC'
      }
      alt={user.displayName}
      color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
      {...other}
    >
      {/* {createAvatar(user.displayName).name} */}
    </MAvatar>
  );
}
