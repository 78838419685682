import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import Axios from '../../utils/axiosInstance';


const initialState = {
  productList: [],
  products: null,
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },

    // DELETE ORDER
    deleteProduct(state, action) {
      const deleteProduct = filter(state.products, (product) => product.id !== action.payload);
      state.products = deleteProduct;
    },

    // GET ORDERS
    getProductListSuccess(state, action) {
      state.isLoading = false;
      state.productList = action.payload;
    },
    // Create Product
    createProducts(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteProduct } = slice.actions;

// ----------------------------------------------------------------------

export function dispatchDeleteProduct(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/products/${productId}`);      
      dispatch(slice.actions.deleteProduct(productId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchNewProduct(products) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/products', products);
      dispatch(slice.actions.createProducts(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateProduct(products) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/products/${products.id}`, products);
      dispatch(slice.actions.createProducts(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/products');
      dispatch(slice.actions.getProductListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}