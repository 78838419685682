import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import { PATH_DASHBOARD } from '../../routes/paths';
import Axios from '../../utils/axiosInstance';

const initialState = {
  orders: [],
  order_logs: null,
  confirm: null,
  semdInvMail: null,
  userList: [],
  countryList: [],
  CustomersList: [],
  customer: null,
  orderId: null,
  paid: null,
  PaidList: [],
  Costs: null,
  CostsList: [],
  CostTypesList: [],
  OrderCostsList: [],
  PaymentTypesList: [],
  TotalCost: [],
  OrderLogList: [],
  reportpinlist: [],
  Salesreportpin: [],
  MasterCostsList: [],
  MonthlyReports: [],
  orderscount: [],
  ReportsCount: [],
  ordersDue: [],
  Orderverifyurl: [],
  Salesreport: [],
  isLoading: false,
  error: false
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },

    // DELETE ORDER
    deleteOrder(state, action) {
      const deleteOrder = filter(state.orders, (order) => order.id !== action.payload);
      state.orders = deleteOrder;
    },

    // DELETE PAYMENT
    deletePayment(state, action) {
      const updatedPayments = state.orderPayments.filter((payment) => payment.id !== action.payload);
      state.orderPayments = updatedPayments;
    },

    deleteCosts(state, action) {
      const updatedCosts = state.orderCosts.filter((costs) => costs.id !== action.payload);
      state.orderCosts = updatedCosts;
    },
 
    // GET ORDERS
    getOrderListSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },
    getOrderDueListSuccess(state, action) {
      state.isLoading = false;
      state.ordersDue = action.payload;
    },
    createCustomer(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    },
  
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    getCountryListSuccess(state, action) {
      state.isLoading = false;
      state.countryList = action.payload;
    },
    getCustomersListSuccess(state, action) {
      state.isLoading = false;
      state.CustomersList = action.payload;
    },
    // Create Order
    createOrder(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },
    // Create Order Log
    createOrderLog(state, action) {
      state.isLoading = false;
      state.semdInvMail = action.payload;
    },
    // Order Paid
    createPaid(state, action) {
      state.isLoading = false;
      state.paid = action.payload;
    },
    getPaidListSuccess(state, action) {
      state.isLoading = false;
      state.PaidList = action.payload;
    },
    // Order Cost
    createCosts(state, action) {
      state.isLoading = false;
      state.Costs = action.payload;
    },
    getCostListSuccess(state, action) {
      state.isLoading = false;
      state.CostsList = action.payload;
    },
    getCostTypesListSuccess(state, action) {
      state.isLoading = false;
      state.CostTypesList = action.payload;
    },
    getOrderCostListSuccess(state, action) {
      state.isLoading = false;
      state.OrderCostsList = action.payload;
    },
    getPaymentTypesListSuccess(state, action) {
      state.isLoading = false;
      state.PaymentTypesList = action.payload;
    },
    getOrderLogListSuccess(state, action) {
      state.isLoading = false;
      state.OrderLogList = action.payload;
    },
    // Create Order Log
    sendInvoiceEmail(state, action) {
      state.isLoading = false;
      state.order_logs = action.payload;
    },
    sendConfirmationEmail(state, action) {
      state.isLoading = false;
      state.confirm = action.payload;
    },
    getTotalCostListSuccess(state, action) {
      state.isLoading = false;
      state.TotalCost = action.payload;
    },
    getReportListSuccess(state, action) {
      state.isLoading = false;
      state.reportpinlist = action.payload;
    },  
    getMonthlyReportListSuccess(state, action) {
      state.isLoading = false;
      state.MonthlyReports = action.payload;
    },
    getOrderCountListSuccess(state, action) {
      state.isLoading = false;
      state.orderscount = action.payload;
    },
    getMonthlyReportCountListSuccess(state, action) {
      state.isLoading = false;
      state.ReportsCount = action.payload;
    },
    OrderVeifyUrl(state, action) {
      state.isLoading = false;
      state.Orderverifyurl = action.payload;
    },
    getSalesPersonListSuccess(state, action) {
      state.isLoading = false;
      state.Salesreport = action.payload;
    },
    SalesReportVerifyPinSuccess(state, action) {
      state.isLoading = false;
      state.Salesreportpin = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteOrder } = slice.actions;

// ----------------------------------------------------------------------

export function dispatchDeleteOrder(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/orders/${orderId}`);
      dispatch(slice.actions.deleteOrder(orderId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function dispatchDeletePayment(paymentId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/order-payment/${paymentId}`);
      dispatch(slice.actions.deletePayment(paymentId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function dispatchDeleteCosts(costsId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/order-cost/${costsId}`);
      dispatch(slice.actions.deleteCosts(costsId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchNewOrder(order) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/orders', order);
      dispatch(slice.actions.createOrder(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateOrder(order) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/orders/${order.id}`, order);
      dispatch(slice.actions.createOrder(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderList(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (userId) {
        response = await Axios.get(`/orders?user_id=${userId}`);
      } else {
        response = await Axios.get('/orders');
      }
      dispatch(slice.actions.getOrderListSuccess(response.data.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// -----------------------Next 7 days  Invoices List-------------------------------------

export function getOrderDueList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/orders');
      dispatch(slice.actions.getOrderDueListSuccess(response.data.data.due_orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/users?role=3');
      dispatch(slice.actions.getUserListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/country');
      dispatch(slice.actions.getCountryListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomersList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/customers');
      dispatch(slice.actions.getCustomersListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function dispatchNewCustomer(customer, navigate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/customers', customer);
      const { order_id, ...otherData } = response.data.data;

      dispatch(slice.actions.createCustomer(otherData));
      dispatch(slice.actions.setOrderId(order_id));

      navigate(`${PATH_DASHBOARD.invoice.root}/${order_id}/edit`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.startLoading());
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateCustomer(customer) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/customers/${customer.id}`, customer);
      dispatch(slice.actions.createCustomer(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------Order Paid Create---------------------------------

export function dispatchNewPaid(paid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/order-payment', paid);
      dispatch(slice.actions.createPaid(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function dispatchUpdatePaid(paid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/order-payment/${paid.id}`, paid);
      dispatch(slice.actions.createPaid(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Order Paid List-------------------------------------

export function getPaidList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-payment');
      dispatch(slice.actions.getPaidListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------Order Cost Create---------------------------------

export function dispatchNewCost(costs) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/order-cost', costs);
      dispatch(slice.actions.createCosts(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.startLoading()); // Set isLoading to false in the finally block
    }
  };
}

export function dispatchUpdateCosts(costs) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/order-cost/${costs.id}`, costs);
      dispatch(slice.actions.createCosts(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Order Cost List-------------------------------------

export function getCostList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-cost');
      dispatch(slice.actions.getCostListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------costs-types List-------------------------------------

export function getCostTypesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/costs-types');
      dispatch(slice.actions.getCostTypesListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Order costs List-------------------------------------

export function getOrderCostList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-cost');
      dispatch(slice.actions.getOrderCostListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------payment-types List-------------------------------------

export function getPaymentTypesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/payment-types');
      dispatch(slice.actions.getPaymentTypesListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// -----------------------------Order Log Create---------------------------------

export function dispatchNewOrderLog(order_log) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/order-activities', order_log);
      dispatch(slice.actions.createOrderLog(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderLogList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-activities');
      dispatch(slice.actions.getOrderLogListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------- Send Invoice Email ------------------------------------

export function dispatchSendInvoiceEmail(send) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/email/send', send);
      dispatch(slice.actions.sendInvoiceEmail(response.success));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------- Send Email when other status  ------------------------------------

export function dispatchSendPlainEmail(send) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/email/plainEmail', send);
      dispatch(slice.actions.sendPlainEmail(response.success));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function dispatchConfirmationEmail(send) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/email/sendConfirmation', send);
      dispatch(slice.actions.sendConfirmationEmail(response.success));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Report List ------------------------------------

export function dispatchReportList(pin) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/verify/pin', pin);
      dispatch(slice.actions.getReportListSuccess(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SalesReportVerifyPin(pin) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/verify/pin', pin);
      dispatch(slice.actions.SalesReportVerifyPinSuccess(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Order confirm URL ------------------------------------

export function dispatchOrderVerifyUrl(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const orderId = id;
      const response = await Axios.post('/verify/url', { orderId });
      dispatch(slice.actions.OrderVeifyUrl(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Report List Month & year-------------------------------------

export function getMonthlyReportList(fromDate, toDate, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (userId) {
        response = await Axios.get(`/orders?user_id=${userId}&from_date=${fromDate}&to_date=${toDate}`);
      } else {
        response = await Axios.get(`/orders?from_date=${fromDate}&to_date=${toDate}`);
      }
      dispatch(slice.actions.getMonthlyReportListSuccess(response.data.data.orders));

      return response;
    } catch (error) {
      // Log the error message
      dispatch(slice.actions.hasError(error));
      return error; // Return the error object
    }
  };
}

// -----------------------Report All Order Finance Total -------------------------------------

// export function getOrderCountList(userId, storeId) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       let response;
//       if (storeId) {
//         response = await Axios.get(`/orders?store_id=${storeId}`);
//       } else if (userId) {
//         response = await Axios.get(`/orders?user_id=${userId}`);
//       } else if (userId && storeId) {
//         response = await Axios.get(`/orders?user_id=${userId}&store_id=${storeId}`);
//       } else {
//         response = await Axios.get('/orders');
//       }
//       dispatch(slice.actions.getOrderCountListSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// -----------------------Report Monthly Order Finance Total -------------------------------------

export function getMonthlyReportCountList(fromDate, toDate, storeId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (storeId && fromDate && toDate && userId) {
        response = await Axios.get(
          `/orders?store_id=${storeId}&from_date=${fromDate}&to_date=${toDate}&user_id=${userId}`
        );
      } else if (userId && fromDate && toDate) {
        response = await Axios.get(`/orders?from_date=${fromDate}&to_date=${toDate}&user_id=${userId}`);
      } else if (storeId && fromDate && toDate) {
        response = await Axios.get(`/orders?store_id=${storeId}&from_date=${fromDate}&to_date=${toDate}`);
      } else if (userId && storeId) {
        response = await Axios.get(`/orders?user_id=${userId}&store_id=${storeId}`);
      } else if (fromDate && toDate) {
        response = await Axios.get(`/orders?from_date=${fromDate}&to_date=${toDate}`);
      } else if (storeId) {
        response = await Axios.get(`/orders?store_id=${storeId}`);
      } else {
        response = await Axios.get('/orders');
      }

      dispatch(slice.actions.getMonthlyReportCountListSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// ----------------------- Salesperson Report -------------------------------------

export function getSalesPersonList(fromDate, toDate, storeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (fromDate && toDate && storeId) {
        response = await Axios.get(`/sales-reports?from_date=${fromDate}&to_date=${toDate}&store_id=${storeId}`);
      } else if (fromDate && toDate) {
        response = await Axios.get(`/sales-reports?from_date=${fromDate}&to_date=${toDate}`);
      } else if (storeId) {
        response = await Axios.get(`/sales-reports?store_id=${storeId}`);
      } else {
        response = await Axios.get('/sales-reports');
      }
      dispatch(slice.actions.getSalesPersonListSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
