import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import Axios from '../../utils/axiosInstance';

const initialState = {
  manufactureList: [],
  manufactureStatus: [],
  manufactureDue: [],
  manufactureCount: [],
  manufactureOrder: [],
  manufactureCountDue: [],
  manufactureActivities: [],
  manufacture: null,
  manufactureLog: null
};

const slice = createSlice({
  name: 'manufacture',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },

    // DELETE manufacture
    deletemanufacture(state, action) {
      const deletemanufacture = filter(state.manufacture, (manufacture) => manufacture.id !== action.payload);
      state.manufacture = deletemanufacture;
    },

    // GET manufacture
    getmanufactureListSuccess(state, action) {
      state.isLoading = false;
      state.manufactureList = action.payload;
    },

    manufactureDueOrderList(state, action) {
      state.isLoading = false;
      state.manufactureDue = action.payload;
    },
    manufactureCountOrderList(state, action) {
      state.isLoading = false;
      state.manufactureCount = action.payload;
    },
    manufactureOrderList(state, action) {
      state.isLoading = false;
      state.manufactureOrder = action.payload;
    },
    manufactureCountDueList(state, action) {
      state.isLoading = false;
      state.manufactureCountDue = action.payload;
    },

    manufactureStatusList(state, action) {
      state.isLoading = false;
      state.manufactureStatus = action.payload;
    },
    // Create manufacture
    createmanufacture(state, action) {
      state.isLoading = false;
      state.manufacture = action.payload;
    },
    createOrderLog(state, action) {
      state.isLoading = false;
      state.manufactureLog = action.payload;
    },
    manufactureActivitiesList(state, action) {
      state.isLoading = false;
      state.manufactureActivities = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteStore } = slice.actions;


// ----------------------------------------------------------------------

export function dispatchNewManufacture(manufacture) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/order-manufacture', manufacture);
      dispatch(slice.actions.createmanufacture(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateManufacture(manufacture) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/order-manufacture/${manufacture.id}`, manufacture);
      dispatch(slice.actions.createmanufacture(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getManufactureList(proId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const orderDetailId = proId;
      const response = await Axios.get(`/order-manufacture/${orderDetailId}`);

      dispatch(slice.actions.getmanufactureListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------- Manufacuture all orders List --------------------------------------

export function ManufacturestatusList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-manufacture');
      dispatch(slice.actions.manufactureStatusList(response.data.data.orderDetails));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------------- Manufacture resize, remake, repair  orders ----------------------------------------


export function ManufactureOrderList(count) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/order-manufacture?is_order=${count}`);
      dispatch(slice.actions.manufactureOrderList(response.data.data.orderDetails));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------------- Manufacture all due date resize, remake, repair  orders ----------------------------------------


export function ManufactureCountDueList(count) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/order-manufacture?is_order=${count}`);
      dispatch(slice.actions.manufactureCountDueList(response.data.data.dueOrders));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------------- Manufacture all due date  orders ----------------------------------------


export function ManufactureDueOrderList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-manufacture');
      dispatch(slice.actions.manufactureDueOrderList(response.data.data.dueOrders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------Manufacture menu all,  resize, remake, repair count----------------------------------------


export function ManufactureCountList(count) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/order-manufacture?is_count=${count}`);
      dispatch(slice.actions.manufactureCountOrderList(response.data.data.OrderCount));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- Manufacture Delete ---------------------------------------

export function dispatchDeleteManufacture(manuId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const id = manuId;
      await Axios.delete(`/order-manufacture/${id}`);
      dispatch(slice.actions.deletemanufacture(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function dispatchManufactureLog(order_log) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/manufacture_activities', order_log);
      dispatch(slice.actions.createOrderLog(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------- Manufacuture activities list --------------------------------------

export function ManufactureActivities(orderDetailId, sectionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (orderDetailId && sectionId) {
        response = await Axios.get(`/manufacture_activities?orderDetailId=${orderDetailId}&sectionId=${sectionId}`);
      } else if (orderDetailId) {
        response = await Axios.get(`/manufacture_activities?orderDetailId=${orderDetailId}`); 
      } 

      dispatch(slice.actions.manufactureActivitiesList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}