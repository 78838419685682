import { sign, verify } from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';
//
import axios from './axios';
import Axios from './axiosInstance';
import { AccessToken } from './strings';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(AccessToken, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;   
  } else {
    localStorage.removeItem(AccessToken);
    delete axios.defaults.headers.common.Authorization;
    delete Axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, sign, verify };
