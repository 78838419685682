import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
  DashboardList: [],
  ordersDue: []  
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getDashboardListSuccess(state, action) {
      state.isLoading = false;
      state.DashboardList = action.payload;
    },

    getOrderDueListSuccess(state, action) {
      state.isLoading = false;
      state.ordersDue = action.payload;
    }   
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { DashboardList } = slice.actions;

export function getDashboardList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/dashboard');
      dispatch(slice.actions.getDashboardListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Next 7 days  Invoices List-------------------------------------

export function getOrderDueList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/orders');
      dispatch(slice.actions.getOrderDueListSuccess(response.data.data.due_orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
