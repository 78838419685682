import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import Axios from '../../utils/axiosInstance';


const initialState = {
  stores: [],
  storeId: null,
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },

    // DELETE ORDER
    deleteStore(state, action) {
      const deleteStore = filter(state.stores, (store) => store.id !== action.payload);
      state.stores = deleteStore;
    },


    // GET ORDERS
    getStoreListSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    },
    // Create Store
    createStore(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteStore } = slice.actions;

// ----------------------------------------------------------------------

export function dispatchDeleteStore(storeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/store/${storeId}`);      
      dispatch(slice.actions.deleteStore(storeId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchNewStore(store) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/store', store);
      dispatch(slice.actions.createStore(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateStore(store) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/store/${store.id}`, store);
      dispatch(slice.actions.createStore(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStoreList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/store');
      dispatch(slice.actions.getStoreListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}