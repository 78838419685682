import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD, PATH_AUTH } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },     
        { path: 'login-unprotected', element: <Login /> }
      ]
    },
     // Dashboard Routes
     {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.general.app} replace /> },
        { path: 'app', element: <GeneralApp /> },
      ]
    },
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.user.list} replace /> },
        { path: 'list', element: <UserList /> },
        { path: 'profile', element: <UserProfile /> },
        { path: '/:id/profile', element: <UserProfile /> }
      ]
    },    
    {
      path: 'store',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.store.list} replace /> },
        { path: 'list', element: <StoreList /> },
      ]
    },
    {
      path: 'invoice',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [ 
        { path: '/', element: <Navigate to={PATH_DASHBOARD.invoice.list} replace /> },
        { path: 'list', element: <InvoiceList /> },
        { path: 'customer/new', element: <CustomerCreate /> },
        { path: '/:id/edit', element: <InvoiceCreate /> },
        { path: '/:id/invoicepdf', element: <MainInvoicePdf /> },
        { path: '/:id/:proId/valuation', element: <ValuationView /> }
      ]
    },
    {
      path: 'manufacture',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.manufacture.list} replace /> },
        { path: 'list', element: <ManufacturerList/> },
        { path: 'remakelist', element: <ManufactureRemakeList /> },
        { path: 'resizelist', element: <ManufactureResizeList /> },
        { path: 'repairlist', element: <ManufactureRepairList /> },
        { path: '/:id/view', element: <ManufactureCreate /> }
      ]                         
    },
    {
      path: 'cost',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.cost.list} replace /> },
        { path: 'list', element: <CostList /> }
      ]                         
    },
    {
      path: 'report',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.report.list} replace /> },
        { path: 'list', element: <ReportVerifyPin /> }
      ]                         
    },
    {
      path: 'salesreport',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.salesreport.list} replace /> },
        { path: 'list', element: <SalesReportPin /> }
      ]                         
    },
    {
      path: 'task',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.task.list} replace /> },
        { path: 'list', element: <TaskList /> }
      ]                         
    },

    {
      path: 'inventory',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.inventory.diamond} replace /> },
        { path: 'diamond', element: <InventoryDiamondList/> }, 
        { path: 'gemstone', element: <InventoryGemstoneList/> },
        { path: 'unmount', element: <InventoryUnmountList/> },
        { path: 'setmount', element: <InventorySetmountList/> },
        { path: 'earrings', element: <InventoryEarringsList/> },
        { path: 'pendants', element: <InventoryPendantsList/> },
        { path: 'bracelet', element: <InventoryBraceletList/> }        
      ]                         
    },    
    {
      path: 'product',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={PATH_DASHBOARD.product.list} replace /> },
        { path: 'list', element: <ProductList/> },  
      ]                         
    },    
    
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },  
    {
      path: '/confirmation-order/:orderId',
      element: <PageOrderConfirmation />
    },
    {
      path: '/',
      element: <Navigate to={PATH_AUTH.login} />,
      children: [
        {
          path: 'components',
          children: [
            // FOUNDATIONS
            { path: 'color', element: <Color /> },
            { path: 'typography', element: <Typography /> },
            { path: 'shadows', element: <Shadows /> },
            { path: 'grid', element: <Grid /> },
            { path: 'icons', element: <Icons /> },
            // MATERIAL UI
            { path: 'accordion', element: <Accordion /> },
            { path: 'alert', element: <Alert /> },
            { path: 'autocomplete', element: <Autocomplete /> },
            { path: 'avatar', element: <Avatar /> },
            { path: 'badge', element: <Badge /> },
            { path: 'breadcrumbs', element: <Breadcrumb /> },
            { path: 'buttons', element: <Buttons /> },
            { path: 'checkbox', element: <Checkbox /> },
            { path: 'chip', element: <Chip /> },
            { path: 'dialog', element: <Dialog /> },
            { path: 'label', element: <Label /> },
            { path: 'list', element: <List /> },
            { path: 'menu', element: <Menu /> },
            { path: 'pagination', element: <Pagination /> },
            { path: 'pickers', element: <Pickers /> },
            { path: 'popover', element: <Popover /> },
            { path: 'progress', element: <Progress /> },
            { path: 'radio-button', element: <RadioButtons /> },
            { path: 'rating', element: <Rating /> },
            { path: 'slider', element: <Slider /> },
            { path: 'snackbar', element: <Snackbar /> },
            { path: 'stepper', element: <Stepper /> },
            { path: 'switch', element: <Switches /> },
            { path: 'table', element: <Table /> },
            { path: 'tabs', element: <Tabs /> },
            { path: 'textfield', element: <Textfield /> },
            { path: 'timeline', element: <Timeline /> },
            { path: 'tooltip', element: <Tooltip /> },
            { path: 'transfer-list', element: <TransferList /> },
            { path: 'tree-view', element: <TreeView /> },
            { path: 'data-grid', element: <DataGrid /> },
            // EXTRA COMPONENTS
            { path: 'chart', element: <Charts /> },
            { path: 'map', element: <Map /> },
            { path: 'editor', element: <Editor /> },
            { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
            { path: 'upload', element: <Upload /> },
            { path: 'carousel', element: <Carousel /> },
            { path: 'multi-language', element: <MultiLanguage /> },
            { path: 'animate', element: <Animate /> },
            { path: 'mega-menu', element: <MegaMenu /> },
            { path: 'form-validation', element: <FormValidation /> }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/GeneralApp')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Main Module
const UserList = Loadable(lazy(() => import('../pages/UserList')));
const StoreList = Loadable(lazy(() => import('../pages/StoreList')));
const InvoiceList = Loadable(lazy(() => import('../pages/InvoiceList')));
const CustomerCreate = Loadable(lazy(() => import('../pages/CustomerCreate')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/InvoiceCreate')));
const MainInvoicePdf = Loadable(lazy(() => import('../pages/MainInvoicePdf')));
const CostList = Loadable(lazy(() => import('../pages/CostList')));
const ReportVerifyPin = Loadable(lazy(() => import('../pages/ReportVerifyPin')));
const SalesReportPin = Loadable(lazy(() => import('../pages/SalesReportPin')));
const TaskList = Loadable(lazy(() => import('../pages/TaskList')));
const UserProfile = Loadable(lazy(() => import('../pages/UserProfile')));
const ValuationView = Loadable(lazy(() => import('../sections/invoice/Valuation/ValuationToolBar')));
const ManufacturerList = Loadable(lazy(() => import('../pages/ManufacturerList')));
const ManufactureCreate = Loadable(lazy(() => import('../pages/ManufactureCreate')));
const ManufactureResizeList = Loadable(lazy(() => import('../pages/ManufactureResizeList')));
const ManufactureRepairList = Loadable(lazy(() => import('../pages/ManufactureRepairList')));
const ManufactureRemakeList = Loadable(lazy(() => import('../pages/ManufactureRemakeList')));
const PageOrderConfirmation = Loadable(lazy(() => import('../pages/PageOrderConfirmation')));
const InventoryDiamondList = Loadable(lazy(() => import('../pages/InventoryDiamondList')));
const InventoryGemstoneList = Loadable(lazy(() => import('../pages/InventoryGemstoneList')));
const ProductList = Loadable(lazy(() => import('../pages/ProductList')));
const InventoryUnmountList = Loadable(lazy(() => import('../pages/InventoryUnmountList')));
const InventorySetmountList = Loadable(lazy(() => import('../pages/InventorySetmountList')));
const InventoryEarringsList = Loadable(lazy(() => import('../pages/InventoryEarringsList')));
const InventoryPendantsList = Loadable(lazy(() => import('../pages/InventoryPendantsList')));
const InventoryBraceletList = Loadable(lazy(() => import('../pages/InventoryBraceletList')));

// Components
const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColors')));
const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
const DataGrid = Loadable(lazy(() => import('../pages/components-overview/material-ui/data-grid')));
const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
const FormValidation = Loadable(lazy(() => import('../pages/components-overview/extra/form-validation')));
