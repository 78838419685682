import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
    TaskList: [],
    TaskActivities: [],
    tasks: null,
    storeId: null,
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },

    // GET ORDERS
    getTaskListSuccess(state, action) {
      state.isLoading = false;
      state.TaskList = action.payload;
    },
    getTaskActivitiesList(state, action) {
      state.isLoading = false;
      state.TaskActivities = action.payload;
    },
    // Create Store
    createTask(state, action) {
      state.isLoading = false;
      state.tasks = action.payload;
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const {TaskList, onToggleFollow, deleteStore } = slice.actions;


// ----------------------------------------------------------------------

export function dispatchNewTask(task) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/tasks', task);
      dispatch(slice.actions.createTask(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateTask(task) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/tasks/${task.id}`, task);
      dispatch(slice.actions.createTask(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTaskList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/tasks');
      
      dispatch(slice.actions.getTaskListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getTaskActivities(orderDetailId, taskType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/tasks/findtask?orderDetailId=${orderDetailId}&taskType=${taskType}`);

      dispatch(slice.actions.getTaskActivitiesList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}